<template>
  <v-card>
    <cp-h1>
      {{ parent.body }}

      <template #subtitle v-if="devMode">
        <router-link
          :to="{
            name: 'report_docs',
            params: {
              widget: 'employee_question_report',
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>
    </cp-h1>

    <div class="d-flex justify-end px-4 mb-5">
      <cp-filters-menu :module="table" noun="Response" />
    </div>

    <v-row class="mb-4 px-4" v-if="typeSlug !== 'text-entry'">
      <v-col v-if="scopes.length > 1" cols="3">
        <table class="card-row-fields__table">
          <tr>
            <td class="text-caption">Viewing By:</td>
            <td>
              <v-menu>
                <template #activator="{on}">
                  <v-btn color="primary" class="mr-2" v-on="on" text small>
                    <v-icon left>mdi-chevron-down</v-icon>
                    {{ selectedScope.label }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group
                    v-model="selectedScopeIndex"
                    color="primary"
                  >
                    <v-list-item v-for="s in scopes" :key="s.value">
                      {{ s.label }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
          <tr>
            <td class="pr-3 text-caption">
              Date Range:
            </td>
            <td>
              <v-menu>
                <template #activator="{on}">
                  <v-btn v-on="on" color="primary" small text>
                    <v-icon left>mdi-chevron-down</v-icon>
                    {{ selectedDateRange.label }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group
                    v-model="selectedDateRangeIndex"
                    color="primary"
                  >
                    <v-list-item v-for="dr in dateRanges" :key="dr.value">
                      <v-list-item-content>{{ dr.label }}</v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col :cols="scopes.length > 1 ? 9 : 12">
        <card :title="`${parent.question_type.name} Summary`">
          <details-ai-histogram
            v-if="typeSlug === 'agreement-importance'"
            :item="meta.summary_object[0]"
          />
          <details-i-histogram
            v-if="typeSlug === 'importance'"
            :item="meta.summary_object[0]"
          />
          <details-a-histogram
            v-if="typeSlug === 'agreement'"
            :item="meta.summary_object[0]"
          />
          <summary-yes-no
            v-if="typeSlug === 'yes-no'"
            :item="meta.summary_object[0]"
          />
          <div
            v-if="['multiple-select', 'multiple-choice'].includes(typeSlug)"
            class="pa-4"
          >
            <div class="mc-responses">
              <div
                v-if="!total || total <= 0"
                class="font-weight-bold text-center mt-3"
              >
                No Responses
              </div>

              <template v-else>
                <h5 class="mc-response-header">
                  Total Responses:
                  <span class="font-weight-bold">{{ total }}</span>
                </h5>
                <div
                  class="mc-response-wrapper"
                  v-for="(response, index) in responses"
                  :key="index"
                >
                  <h5 class="mc-response-text">
                    <span class="font-weight-bold mr-2">{{
                      response.label
                    }}</span>
                    {{ response.value }}
                  </h5>
                  <div
                    class="mc-response-bar"
                    :style="responseBarStyle(response.value)"
                  ></div>
                </div>
              </template>
            </div>
          </div>
        </card>
      </v-col>
    </v-row>

    <div
      v-if="
        ['multiple-select', 'multiple-choice', 'text-entry'].includes(typeSlug)
      "
      class="pb-4"
    >
      <cp-h1 v-if="['multiple-select', 'multiple-choice'].includes(typeSlug)">
        <v-icon class="mr-3">mdi-chat-question-outline</v-icon>
        Other responses to: Why aren't you very likely to renew?
      </cp-h1>
      <div v-for="(r, i) in data" :key="i" class="px-4">
        <v-alert
          v-if="typeSlug === 'text-entry' ? r.comment : r.other_text_response"
          icon="mdi-message-outline"
          color="blue-grey"
          text
          class="py-1"
        >
          <div class="commentQuestion text-caption d-flex align-center">
            <span v-if="r.location_name" class="d-flex align-center">
              <v-icon class="mr-1" small>mdi-home</v-icon>
              {{ r.location_name }}
              <span class="mx-3">|</span>
            </span>
            <v-icon class="mr-1" small>mdi-account</v-icon>
            {{ r.first_name }} {{ r.last_name }}
            <span class="mx-3">|</span>
            {{ toMDY(r.created_at) }}
          </div>
          <span v-html="commentTextAsHtml(r)" />
        </v-alert>
      </div>

      <v-pagination
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </div>
    <div v-else>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #header.name>
          {{ selectedScope.label }}
        </template>

        <template #item.response_summary="{item}">
          <details-ai-histogram
            v-if="typeSlug === 'agreement-importance'"
            :item="item"
          />
          <details-i-histogram v-if="typeSlug === 'importance'" :item="item" />
          <details-a-histogram v-if="typeSlug === 'agreement'" :item="item" />
          <summary-yes-no v-if="typeSlug === 'yes-no'" :item="item" />
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </div>
  </v-card>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";

const typeSlugAttributeMap = {
  "agreement-importance": "agreement_importance",
  importance: "ai_importance",
  agreement: "ai_agreement",
  likelihood: "likelihood",
};

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  computed: {
    responses() {
      return get(this.meta, ["summary_object", "multiple_select"], []);
    },

    total() {
      return this.responses
        .map(({ value = 0 } = {}) => value)
        .reduce((sum, val) => sum + val, 0);
    },

    max() {
      return this.responses[0].value;
    },

    typeSlug() {
      return get(this.parent, "question_type.slug");
    },

    scopeHeaders() {
      const headers = [{ text: "", value: "name" }];
      if (Array.isArray(this.data) && this.data.length && this.data[0].sbx) {
        headers.push({
          text: "SBX",
          value: "sbx",
          align: "center",
          sortable: false,
        });
      }

      const value = get(typeSlugAttributeMap, this.typeSlug);
      if (this.typeSlug === "likelihood") {
        headers.push({
          text: "Likley to remain",
          value: "likely_to_renew_percentage",
        });
      } else if (value) {
        headers.push({
          text: "Score",
          align: "center",
          value,
        });
      }
      headers.push(
        {
          text: "Responses",
          value: "response_count",
          align: "center",
          sortable: false,
        },
        {
          text: "Summary",
          value: "response_summary",
          sortable: true,
          align: "center",
        }
      );
      return { default: headers };
    },
  },
  methods: {
    responseBarStyle(value) {
      let percentage = (value / this.max) * 100;
      let amount = 50 * (percentage / 100);
      let final = 240 - Math.round(amount);
      return {
        width: `${(value / this.max) * 100}%`,
        backgroundColor: `rgb(${final}, ${final}, ${final * 1.45})`,
      };
    },

    commentTextAsHtml(item) {
      const text =
        this.typeSlug === "text-entry"
          ? item.comment
          : item.other_text_response;
      return text.replace(/\r\n?|\n/g, "<br />");
    },
  },
};
</script>

<style lang="scss" scoped>
.mc-responses {
  text-align: left;
  margin-bottom: 12px;

  .mc-response-header {
    position: relative;
    left: 6px;
  }

  .mc-response-wrapper {
    position: relative;
    height: 28px;
    margin-top: 2px;
    margin-bottom: 2px;

    .mc-response-text {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      padding: 0 6px;
      height: 28px;
    }

    .mc-response-bar {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}

.text-h6 {
  background: rgba($gray-3, 0.25);
  margin-bottom: 14px;
  padding: 4px 16px;
}

.commentsInMonth {
  padding: 0 16px;
}

::v-deep .v-alert--text {
  padding-top: 4px;
  padding-bottom: 0;
}

.commentQuestion {
  color: $gray-4;
  margin-bottom: 8px;
}

.commentDate {
  color: $gray-4;
  text-align: right;

  margin-top: 8px;
}
</style>
